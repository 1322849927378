import React from 'react';
import './Closing.css';
import lightningIcon from '../../images/lightning.png'; // Placeholder for your actual icons
import thunderIcon from "../../images/thunder.svg"; // Placeholder for your actual icons
import arrowIcon from '../../images/arrow.svg';
const Closing = () => {
  return (
    <section className="closing-section">
      <h3>Ready to unlock the full potential of your crypto assets?</h3>
      <p>Download the Tonkeeper app now and start leveraging the Tonkeeper Battery feature</p>
      <button onClick={() => window.open('https://app.tonkeeper.com/settings')} className="hero-button">
        <div className={'button-content'}>
          <img alt={'thunder'} src={thunderIcon}/>
          <span>Charge Your Battery</span>
        </div>
      </button>
      <div className={'closing-fading-line'}></div>
      <p className="download-link">
        If you do not have Tonkeeper personal wallet yet download it from <a href="https://tonkeeper.com/">here <img src={arrowIcon} className={'arrow'}/></a>
      </p>
      <div className="lightning-icons">
        <img src={lightningIcon} alt="Lightning" className="lightning-icon right-bottom blure"/>
        <img src={lightningIcon} alt="Lightning" className="lightning-icon right-top"/>
        <img src={lightningIcon} alt="Lightning" className="lightning-icon left-top blur"/>
        <img src={lightningIcon} alt="Lightning" className="lightning-icon left-bottom"/>
      </div>
    </section>
  );
};

export default Closing;

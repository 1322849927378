import React, { useState, useEffect, useRef } from 'react';
import './NavBar.css';
import menuIcon from '../../images/menu-icon.svg'; // Adjust the path as per your project structure
const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef();

  const toggleMenu = () => {
    window.innerWidth < 768 &&
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar" ref={navRef}>

      {/*<div className={'brand-container'}>*/}
      {/*  <div className={'logo-container'}>*/}
      {/*    <img src={tonIcon}/>*/}
      {/*  </div>*/}
      {/*  <h3 className={'brand-name'}>Tonkeeper</h3>*/}
      {/*</div>*/}
      <div className={`navbar-menu ${isOpen ? 'is-open' : ''}`}>
        <ul>
          <li><a href="https://tonkeeper.helpscoutdocs.com/" target={'_blank'} onClick={toggleMenu}>FAQ</a></li>
          <li><a href="https://t.me/help_tonkeeper_bot" target={'_blank'} onClick={toggleMenu}>Support</a></li>
          <li><a href="https://github.com/tonkeeper/wallet-api" target={'_blank'} onClick={toggleMenu}>Documentation</a></li>
          <li><a href="https://t.me/help_tonkeeper_bot" target={'_blank'} onClick={toggleMenu}>Bug Bounty</a></li>
          <li><a href="https://github.com/tonkeeper" target={'_blank'} onClick={toggleMenu}>GitHub</a></li>
        </ul>
      </div>
      <div className="navbar-brand">
        {!isOpen && (

          <img src={menuIcon} alt="Menu" className="menu-icon" onClick={toggleMenu}/>
        )}

      </div>
    </nav>
  );
};

export default NavBar;

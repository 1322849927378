import React from 'react';
import './HeroSection.css';
import batteryIcon from '../../images/battery-full-charge.svg'; // Adjust the path as per your project structure
import leftPointer from '../../images/left-pointer.svg';
import rightPointer from '../../images/right-pointer.svg';
import speedIcon from '../../images/speed.svg';
import moneyBagIcon from '../../images/money-bag.svg';
import thunderIcon from '../../images/thunder.svg';
import tonIcon from '../../images/ton.svg';

const HeroSection = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <h3>Tonkeeper Battery</h3>
        <h1>Supercharge Your Crypto Transactions</h1>
        <div className="hero-info">
          <img src={batteryIcon} alt={'Battery'} className={'battery-icon'}/>
        </div>
          <div className={'popup-container'}>
            <div className={'popup-item'}>
              <div className="info-box left">
                <img alt={'speed'} src={speedIcon}/>
                <h2>80%</h2>
                <h3>Faster</h3>
                <span>With one-tap payment, no exchanges involved</span>
              </div>
              <img alt={'some pointer'} src={leftPointer} className={'left-pointer'}/>
            </div>
            <div className={'popup-item'}>
              <img alt={'some pointer'} src={rightPointer} className={'right-pointer'}/>
              <div className="info-box">
                <img alt={'money'} src={moneyBagIcon}/>
                <h2>25%</h2>
                <h3>Lower fees</h3>
                <span>Save 25% for the network transaction fees.</span>
              </div>
            </div>
          </div>
        <div className={'cta-container'}>
          <button onClick={() => window.open('https://app.tonkeeper.com/')} className="hero-button">
            <div className={'button-content'}>
              <img alt={'thunder'} src={thunderIcon}/>
              <span>Charge Your Battery</span>
            </div>
          </button>
          <button onClick={() => window.open('https://tonkeeper.com/')} className="get-tonkeeper-button">
            <div className={'button-content'}>
              <img alt={'ton'} src={tonIcon}/>
              <span>Get Tonkeeper</span>
            </div>
          </button>
        </div>
      </div>

    </div>
  );
};

export default HeroSection;

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className={'fading-line'}></div>
      <div className="footer-container">
        <div className={'sections-container'}>
          <div className="footer-section">
            <h4>Company</h4>
            <ul>
              <li><a target={'_blank'} href="https://t.me/tonkeeper_news">News</a></li>
              <li><a target={'_blank'} href="https://t.me/tonkeeperdev">Chat</a></li>
              <li><a target={'_blank'} href="https://twitter.com/tonkeeper">Twitter</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Resources</h4>
            <ul>
              <li><a target={'_blank'} href="https://tonkeeper.helpscoutdocs.com/">FAQ</a></li>
              <li><a target={'_blank'} href="https://t.me/help_tonkeeper_bot">Support</a></li>
              <li><a target={'_blank'} href="https://t.me/help_tonkeeper_bot">Suggest a Feature</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Developers</h4>
            <ul>
              <li><a target={'_blank'} href="https://github.com/tonkeeper/wallet-api">Documentation</a></li>
              <li><a target={'_blank'} href="https://t.me/help_tonkeeper_bot">Bug Bounty</a></li>
              <li><a target={'_blank'} href="https://github.com/tonkeeper">GitHub</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Legal</h4>
            <ul>
              <li><a href="https://tonkeeper.com/terms">Terms</a></li>
              <li><a href="https://tonkeeper.com/privacy">Privacy</a></li>
            </ul>
          </div>
        </div>
        <div className="footer-award">
          <img alt={'#1 wallet in top app'} src="https://ton.app/a2/badge/topapp?appName=tonkeeper"/>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Tonkeeper. All rights reserved.<span className={'minor-text'}> All trademarks are the property of their respective owners.</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;

import HowItWorksImg from '../../images/how-it-works.png';
import HowItWorksMobileImg from '../../images/how-it-works-mobile.png';
import './HowItWorks.css'
import {useEffect, useState} from "react";
const HowItWorks = () => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const updateImage = () => {
      if (window.innerWidth <= 768) {
        setImage(HowItWorksMobileImg); // Replace with your actual desktop image path
      } else {
        setImage(HowItWorksImg); // Replace with your actual mobile image path
      }
    };

    updateImage();
    window.addEventListener('resize', updateImage);
    return () => window.removeEventListener('resize', updateImage);
  }, []);

  return (
    <section className={'how-it-works'}>
      <h2>How Tonkeeper Battery Works</h2>
      <img alt={'using battery flow'} src={image}/>
    </section>
  )
}

export default HowItWorks

import NavBar from './sections/NavBar';
import HeroSection from './sections/HeroSection';
import './App.css'
import Footer from "./sections/Footer";
import backShadow from "./images/backshadow.svg";
import React from "react";
import HowItWorks from "./sections/HowItWorks";
import Closing from "./sections/Closing";
import Benefits from "./sections/Benefits";
const App = () => {
  return (
    <div className={'app'}>
      <NavBar/>
      <HeroSection/>
      <Benefits/>
      <HowItWorks/>
      <Closing/>
      <Footer/>
    </div>
  );
};

export default App;

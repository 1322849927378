import React from 'react';
import './Benefits.css';
import gaslessPaymentsIcon from '../../images/credit-card.svg'; // Placeholder for your actual icons
import flexibleFundingIcon from '../../images/coin.svg'; // Placeholder for your actual icons
import seamlessSwapsIcon from '../../images/transfer.svg'; // Placeholder for your actual icons
import hassleFreeIcon from '../../images/invoice.svg'; // Placeholder for your actual icons
import batteryPhoneImg from '../../images/battery-phone.png'
const Benefits = () => {
  return (
    <section className="benefits">
      <h2>Why Tonkeeper Battery?</h2>
      <img src={batteryPhoneImg} className={'battery-phone'}/>
      <div className={'row'}>
        <div className="benefits-container">
          <div className="benefit">
            <div className="icon">
              <img src={gaslessPaymentsIcon} alt="Gasless Payments"/>
            </div>
            <div className="description">
              <h3>Gasless Payments</h3>
              <p>Don't use TON coins for covering transaction fees</p>
            </div>
          </div>
          <div className="benefit">
            <div className="icon">
              <img src={flexibleFundingIcon} alt="Flexible Funding"/>
            </div>
            <div className="description">
              <h3>Flexible Funding</h3>
              <p>Charge your battery using in-app purchases, TON or stablecoins</p>
            </div>
          </div>
        </div>
        <div className="benefits-container">
          <div className="benefit">
            <div className="icon">
              <img src={seamlessSwapsIcon} alt="Seamless Swaps & Transfers"/>
            </div>
            <div className="description">
              <h3>Seamless Swaps & Transfers</h3>
              <p>Pay automatically for Swaps & NFT transfers using battery charges</p>
            </div>
          </div>
          <div className="benefit">
            <div className="icon">
              <img src={hassleFreeIcon} alt="Hassle-Free Transactions"/>
            </div>
            <div className="description">
              <h3>Hassle-Free Transactions</h3>
              <p>You don’t need exchanges & KYC validations to cover transaction fees</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
